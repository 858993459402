import React from "react";
import { Table } from "antd";
import "../App.css";
import { useNavigate } from "react-router-dom";
import {getAllUsers, getUserDetail} from '../../src/services'
const usersColumns = [
  {
    title: "First Name",
    dataIndex: "firstname",
    key: "firstname",
  },
  {
    title: "Last Name",
    dataIndex: "lastname",
    key: "lastname",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Country",
    dataIndex: "countrycode",
    key: "countrycode",
  },
  {
    title: "Mobile Number",
    dataIndex: "mobilenumber",
    key: "mobilenumber",
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
  },
];





const Dashboard = () => {
  const navigate = useNavigate()
  
   const [usersDataSource,setUsersDataSource] = React.useState([
  ]);
  const [token, setToken] = React.useState(localStorage.getItem('token'))
  React.useEffect(()=>{
    getAllUsers(token).then((res, index)=>{
      setUsersDataSource
      (
        res.map((row,index) => ({
          key: row.uid,
          firstname: row.firstName,
          lastname: row.lastName,
          email: row.email,
          countrycode:row.countryOfResidence || "-",
          mobilenumber:row.phone  || "-",
          details: <div onClick={()=>getUserDetailRoute(row.uid)}> <a className="nav-link " ><p style={{color:'blue', fontWeight:'bold', textDecoration:'underline', marginTop:'20px', cursor:'pointer'}}>Details</p></a></div>,
        }))
      );
     
    })
  },[])

  const getUserDetailRoute = (id) =>{
  
    getUserDetail(id, token).then(res=>{
    
        navigate('/usersDetail',{
          state: {
              data: res
          },
      })
    })
    }
  return (
    
    <div>
      <main id="main" className="main">
        {/* End Page Title */}
        <section className="section dashboard">
          <div className="row">
            {/* Left side columns */}
            <div className="col-lg-12">
              <div className="row">
    
                <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">Users / Customers</h5>
                      <Table
                        dataSource={usersDataSource}
                        columns={usersColumns}
                      />
                      ;
                    </div>
                  </div>
                </div>

             

     
              </div>
            </div>
         
          </div>
        </section>
      </main>
  
    </div>
  );
};

export default Dashboard;
