import React from "react";
import { Table } from "antd";
import "../App.css";
import {getDashboard} from '../services'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Monthly Sales',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];




const campaignsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];



const productsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
];

const Dashboard = () => {

  const [dashboardData, setData] = React.useState(null)
  const [token, setToken] = React.useState(localStorage.getItem('token'))
  React.useEffect(()=>{
    getDashboard(token).then(res=>{

      setData(res)
    })
  },[token])
  const [productsDataSource, setProductDataSource] = React.useState([
   
    
  
  ]);

  const [campaignsDataSource, setCampaignsDataSource] = React.useState([
   
  ]);

  const [data,setLineData] = React.useState({
    labels,
    datasets: [
      {
        label: 'Products',
        data: [22,33,44,55,644,1000,222],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Draws',
        data: [22,33,44,55,644,1000,622],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  });

  React.useEffect(()=>{
   const sales= dashboardData?.productSales.map(res=>{
      return +res.price
    })
    console.log('Sal;es', sales)
    
  },[dashboardData?.productSales])

 
  // {
  //   key: "2",
  //   name: "John",
  //   status: "In Progress",
  // },

  React.useEffect(()=>{
    setCampaignsDataSource(
      dashboardData?.campaigns.map((res)=> {return ({
      
  //
    key: res.id,
    name: res.name,
    status: res.status,

      })
      })
    )
    
  },[dashboardData?.campaigns])

  

  React.useEffect(()=>{
    setProductDataSource(
      dashboardData?.products.map((res)=> {return ({
      
        key: res.id,
        name: res.name,
        image: (
          <img
            style={{ height: 80, width: 80 }}
            src={res.image}
          ></img>
        ),
        price: <p style={{ fontWeight: "bold" }}>{res.price} AED</p>,
        
      })
      })
    )
    
  },[dashboardData?.products])
  return (
    <div>
      <main id="main" className="main">
        {/* End Page Title */}
        <section className="section dashboard">
          <div className="row">
            {/* Left side columns */}
           
            <div className="col-lg-12">
              <div className="row">
                {/* Sales Card */}
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Total Products</h5>
                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-cart" />
                        </div>
                        <div className="ps-3">
                          <h6>{dashboardData?.totalProducts}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Total Campaigns</h5>
                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-megaphone" />
                        </div>
                        <div className="ps-3">
                          <h6>{dashboardData?.totalCampaigns}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Sales Card */}
                {/* Revenue Card */}
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <h5 className="card-title">Total Draws</h5>
                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-currency-dollar" />
                        </div>
                        <div className="ps-3">
                        <h6>{dashboardData?.totalDraws} AED</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Revenue Card */}
                {/* Customers Card */}
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <h5 className="card-title">Total Users</h5>
                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-people" />
                        </div>
                        <div className="ps-3">
                          <h6>{dashboardData?.totalUsers}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">Recent 10 Products</h5>
                      <Table
                        dataSource={productsDataSource}
                        columns={productsColumns}
                      />
                      
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">Recent 10 Campaigns</h5>
                      <Table
                        dataSource={campaignsDataSource}
                        columns={campaignsColumns}
                      />
                      
                    </div>
                  </div>
                </div>
                {/* <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                <Line options={options} data={data} />;
                </div>
                </div>
                </div> */}
                {/* End Top Selling */}
              </div>
            </div>
            {/* End Left side columns */}
            {/* Right side columns */}
            <div className="col-lg-4">{/* Budget Report */}</div>
            {/* End Right side columns */}
          </div>
        </section>
      </main>
   
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
      {/* Vendor JS Files */}
      {/* Template Main JS File */}
    </div>
  );
};

export default Dashboard;
