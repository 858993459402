import React from "react";
import { Table } from "antd";
import "../App.css";
import moment from "moment";
import { useLocation } from "react-router-dom";

const productsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Qty",
    dataIndex: "qty",
    key: "qty",
  },
  // {
  //   title: "Donate",
  //   dataIndex: "donate",
  //   key: "donate",
  // },
  {
    title: "No of coupons",
    dataIndex: "noofcoupons",
    key: "noofcoupons",
  },
  {
    title: "Purchased Date",
    dataIndex: "purchaseddate",
    key: "purchaseddate",
  },
];

const tableOfWinsColumns = [
  {
    title: "Coupon Number",
    dataIndex: "couponnumber",
    key: "couponnumber",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Draw Date",
    dataIndex: "drawdate",
    key: "drawdate",
  },
];

const UsersDetails = () => {
  const location = useLocation();
  const [productsDataSource, setProductDataSource] = React.useState([]);
  const [tableOfWinsDataSource, setTableOfWinsDataSource] = React.useState([]);
  React.useEffect(() => {
    setProductDataSource(
      location?.state?.data?.orderItems?.map((res) => {
        return {
          key: res.id,
          name: res?.campaign?.product?.name,
          image: (
            <img
              style={{ height: 80, width: 80 }}
              src={res?.campaign?.product?.image}
            ></img>
          ),
          price: (
            <p style={{ fontWeight: "bold" }}>
              {res?.campaign?.product?.price} AED
            </p>
          ),
          qty: res.quantity,

          noofcoupons: res?.noOfCoupons,
          purchaseddate: moment(res?.createdAt).format(),
        };
      })
    );

    setTableOfWinsDataSource(
      location?.state?.data?.winners?.map((res) => {
        return {
          key: res.id,
          couponnumber: res?.campaign?.noOfCoupons,
          name: res?.campaign?.name,
          drawdate: moment(res?.createdAt).format(),
        };
      })
    );
  }, [location?.state?.data?.orderItems]);
  return (
    console.log("location", location.state.data) || (
      <div>
        <main id="main" className="main">
          {/* End Page Title */}
          <section className="section dashboard">
            <div className="row">
              {/* Left side columns */}
              {/* <div className="col-xxl-4 col-md-6">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Purchased Coupon</h5>
                      <div >
                          <div style={{display:'flex', flexDirection:'row'}}>
                              <p style={{fontWeight:'bold', marginTop:10}}>Image: </p>
                             
                           <img style={{height:50, width:50, marginLeft:20, borderRadius:100}} src="https://picsum.photos/200/300"/>
                        </div>
                       
                        <div style={{width:'100%', height:1, backgroundColor:'black', marginTop:10, marginBottom:10}}/>
                        <div style={{display:'flex', flexDirection:'row', marginTop:15}}>
                              <p style={{fontWeight:'bold'}}>Ticket Number: <span style={{fontWeight:'normal'}}>#12323</span> </p>
                             
                      
                        </div>
                        <div style={{width:'100%', height:1, backgroundColor:'black', marginTop:10, marginBottom:10}}/>
                       
                        <div style={{display:'flex', flexDirection:'row', marginTop:15}}>
                              <p style={{fontWeight:'bold'}}>Product Name:  <span style={{fontWeight:'normal'}}>Danki</span> </p>
                             
                      
                        </div>
                        <div style={{width:'100%', height:1, backgroundColor:'black', marginTop:10, marginBottom:10}}/>
                       
                        <div style={{display:'flex', flexDirection:'row', marginTop:15}}>
                              <p style={{fontWeight:'bold'}}>Purchased On:  <span style={{fontWeight:'normal'}}>2020-12-09</span> </p>
                             
                      
                        </div>
                        <div style={{width:'100%', height:1, backgroundColor:'black', marginTop:10, marginBottom:10}}/>
                       
                        <div style={{display:'flex', flexDirection:'row', marginTop:15}}>
                              <p style={{fontWeight:'bold'}}>Draw Date: <span style={{fontWeight:'normal'}}>2020-12-09</span> </p>
                             
                      
                        </div>
                        <div style={{width:'100%', height:1, backgroundColor:'black', marginTop:10, marginBottom:10}}/>
                       
                      </div>
                    </div>
                  </div>
                </div> */}
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12">
                    <div className="card recent-sales overflow-auto">
                      <div className="card-body">
                        <h5 className="card-title">Purchased Products</h5>
                        <Table
                          dataSource={productsDataSource}
                          columns={productsColumns}
                        />
                      </div>
                      {/* <p
                        style={{
                          position: "absolute",
                          right: 0,
                          bottom: 0,
                          left: 0,
                          marginLeft: 20,
                          fontWeight: "bold",
                        }}
                      >
                        Total amount : 1000
                      </p> */}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="card recent-sales overflow-auto">
                      <div className="card-body">
                        <h5 className="card-title">Wins</h5>
                        <Table
                          dataSource={tableOfWinsDataSource}
                          columns={tableOfWinsColumns}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    )
  );
};

export default UsersDetails;
