import React, { createContext, useState, useContext } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState([]);

  const addDataToUser = (item) => {
    localStorage.setItem("userDataLocal", JSON.stringify(item));
    setUserData([...userData, item]);
  };

  const removeDataFromUser = (itemId) => {
    setUserData(userData.filter((item) => item.id !== itemId));
  };

  const value = { userData, addDataToUser, removeDataFromUser };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("user User must be in provider");
  }
  return context;
};
