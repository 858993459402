import React from "react";
import { Table } from "antd";
import "../App.css";
import ModalWinner from '../components/ModalWinner'
import { getWinners } from "../services";
import moment from 'moment'


const dataColumns = [
  {
    title: "Winner Name",
    dataIndex: "winnername",
    key: "winnername",
  },
  {
    title: "Prize",
    dataIndex: "prize",
    key: "prize",
  },
  {
    title: "Coupon Number",
    dataIndex: "couponnumber",
    key: "couponnumber",
  },
  {
    title: "Announced On",
    dataIndex: "announcedon",
    key: "announcedon",
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
  },
];



const Winners = () => {
    const [modalVisible, setModalVisible] = React.useState(false)
    const [dataSource, setDataSource] = React.useState([
      
      ])
      const [data, setData]=React.useState(null)

      const [token, setToken] = React.useState(localStorage.getItem('token'))
      React.useEffect(()=>{
        getWinners(token).then((res, index)=>{
          setDataSource
          (
            res.map((row,index) => (
              {
                key: row.id,
                winnername: row?.coupon?.user?.firstName + ' ' + row?.coupon?.user?.lastName,
                prize: row?.campaign?.prize?.name,
                couponnumber:row?.coupon?.number,
                announcedon:moment(row?.updatedAt).format(),
                details:<p onClick={()=>{
                  setData(row)
                  setModalVisible(true)
                }} style={{color:'blue',textDecoration:'underline', fontWeight:'bold', marginTop:20 , cursor:'pointer'}}>Details</p>
              }
            ))
          );
         
        })
      },[])
    const updateModal = React.useCallback((params) =>{
        setModalVisible(params)
    },[modalVisible])
  return (
    <div>
        {modalVisible && <ModalWinner data={data} updateModal={updateModal} modalVisible = {modalVisible}/>}
      <main id="main" className="main">
        {/* End Page Title */}
        <section className="section dashboard">
          <div className="row">
            {/* Left side columns */}
            <div className="col-lg-12">
              <div className="row">
             
               

                <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">Winners</h5>
                      <Table
                        dataSource={dataSource}
                        columns={dataColumns}
                      />
                      ;
                    </div>
                  </div>
                </div>

                {/* End Top Selling */}
              </div>
            </div>
          
          </div>
        </section>
      </main>
   
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
      {/* Vendor JS Files */}
      {/* Template Main JS File */}
    </div>
  );
};

export default Winners;
