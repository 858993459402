import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, Popconfirm, Table, Typography, Button,Select } from 'antd';
import FormModal from '../components/FormModalCurrency'
import {getProducts,deleteProdcut,updateProduct, getCurriencies, updateCurriencies, deleteCurriencies} from '../../src/services'
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
              {title === 'Status' ? (
                <Select>
                <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                <Select.Option value="INACTIVE">INACTIVE</Select.Option>
                </Select>
            ) : <Input/>}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
const MultiCurrency = () => {
  const [modalVisible, setModalVisible] = React.useState(false)
  const [dataSource, setDataSource] = useState([]);
  const [token, setToken] = React.useState(localStorage.getItem('token'))
  React.useEffect(()=>{
    getCurriencies(token).then((res, index)=>{
      setDataSource
      (
        res.map((row,index) => ({
          key: row.id,
          name: row.name,
         code:row.code,
         exchangerate:row.exchangeRate,
         decimalplaces: row.decimalPlaces,
         status:row.status

        }))
      );
     
    })
  },[])
  const [count, setCount] = useState(2);
  const handleDelete = async (key) => {
   
    await deleteCurriencies(key, token).then((res)=>{
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);

    })

  
  };
  const defaultColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%',
      editable: true,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      editable: true,
    },
    {
      title: 'Exchange Rate',
      dataIndex: 'exchangerate',
        editable: true,
    },
    {
      title: 'Decimal Places',
      dataIndex: 'decimalplaces',
        editable: true,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        editable: true,
      },
    
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
            <div>

<Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>

            </div>
         
        )
       
      }
    },
  ];
  const handleAdd = (name, code, exchangerate, decimalplaces, status) => {
    const newData = {
      key: count,
      name: name,
      code: code,
      exchangerate: exchangerate,
      decimalplaces:decimalplaces,
      status:status
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const updateModal = React.useCallback((param)=>{
    setModalVisible(param)
  },[modalVisible])

  React.useEffect(()=>{
  },[modalVisible])

  const [form] = Form.useForm();
  const [data, setData] = useState(dataSource);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setDataSource(newData)
        setEditingKey('');
        const payload = {
          id: newData[index].key,
          name:newData[index].name,
          code:newData[index].code,
          exchangeRate:+newData[index].exchangerate,
          decimalPlaces: Number(newData[index].decimalplaces),
          status:newData[index].status
        }
        
        updateCurriencies(payload, token)

      } else {
        newData.push(row);
        setData(newData);
        setDataSource(newData)
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
        {modalVisible && <FormModal modalVisible={modalVisible} updateModal={updateModal} handleAdd={handleAdd} />}
    <main id="main" className="main">

      <section className="section dashboard">
    <div>
      <Button
        onClick={()=> {
            // handleAdd()
            setModalVisible(true)
        }}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Add new currency
      </Button>

      <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={dataSource}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
      {/* <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={}
        columns={columns}
      /> */}
    </div>
    </section>
    </main>
    </div>
  );
};
export default MultiCurrency;






