import React,{useState} from "react";

import { UploadOutlined } from '@ant-design/icons';
import {Form, Input, Button, Select,Checkbox, Upload, Table,message } from 'antd';
import "../App.css";
import { getNotifications,getAllUsers,generateNotifications } from "../services";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../registerFirebase';
import moment from 'moment'


  
  
  const notificationsColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },

  ];

const Notifications = () => {
  const [notificationsDataSource,setNotificationsDataSource] = React.useState([

  ]);
  const [messageApi, contextHolder] = message.useMessage();
  const [status,setStatus] = React.useState(false)
   const [file, setFile] = useState("");
   const [percent, setPercent] = useState(0);
  const [usersDataSource,setUsersDataSource] = React.useState([
  ]);

  const [token, setToken] = React.useState(localStorage.getItem('token'))

  React.useEffect(()=>{

  },[status])
 
    const [form] = Form.useForm()
    const onFinish = () =>{

    }
      function handleChange(event) {
              setFile(event.target.files[0]);
              handleUpload(event.target.files[0])
          }
         const handleUpload = (file) => {
                  if (!file) {
                      alert("Please upload an image first!");
                  }
           
                  const storageRef = ref(storage, `/files/${file.name}`);
           
                  // progress can be paused and resumed. It also exposes progress updates.
                  // Receives the storage reference and the file to upload.
                  const uploadTask = uploadBytesResumable(storageRef, file);
           
                  uploadTask.on(
                      "state_changed",
                      (snapshot) => {
                          const percent = Math.round(
                              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                          );
           
                          // update progress
                          setPercent(percent);
                      },
                      (err) => console.log(err),
                      () => {
                       
                          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              form.setFieldValue('image', url)
             
      
                          });
                      }
                  );
              };
    React.useEffect(()=>{
      getAllUsers(token).then((res, index)=>{
        setUsersDataSource(res)
       
      })
      getNotifications(token).then((res, index)=>{
        setNotificationsDataSource
        (
          res.map((row,index) => ({
            
              key: row.id,
              date: moment(row?.createdAt).format(),
              message: row?.message,
           
          }))
        );
       
      })
    },[])
  return (
    <div>
    
      <main id="main" className="main">
        {/* End Page Title */}
        <section className="section dashboard">
        <br/>
            <br/>
          <div className="row">
            {/* Left side columns */}
           
            <div className="col-lg-12">
            <Form labelCol={{ xs: { span: 6 } }} wrapperCol={{ xs: { span: 12 } }} form={form} onFinish={onFinish} scrollToFirstError>
                    <Form.Item name="userId" label="Select user" >
                    <Select name="userId" placeholder="Please select a user">
              {usersDataSource.map(res=>{
                return(
              <Select.Option value={res.uid}>{res.firstName + ' ' + res.lastName}</Select.Option>
                )
            })}
              
             
            </Select>
                    </Form.Item>

                    <Form.Item name='allusers' label="All Users" valuePropName='checked'>
        <Checkbox />
    </Form.Item>

    <Form.Item  rules={[{ required: true, message: "This field is required." }]} name="image" label='Image'> 
                    
                                <Input style={{position:'absolute', top:0}}  name="image"  type="file" onChange={handleChange} accept="/image/*" />
                                
                           <p style={{marginTop:20, fontWeight:'bold'}}>{percent} %</p>
                                        </Form.Item>
                   
                    <Form.Item name="message" label="Message" rules={[{ required: true, message: "This field is required." }]}>
                        <Input />
                    </Form.Item>
                   
                </Form>
                <div style = {{alignSelf:'center', display:'flex', justifyContent:'center'}} >
                <Button  key="submit" type="primary" className="flex-end" onClick={()=>{
                  const {allusers, image,userId,message}=form.getFieldValue()
                  if(image && userId && message){
                    const data={
                      "sendToAllUsers": allusers || false,
    "userId": userId,
    "image": image,
    "title": message,
    "message": message
                    }
                    generateNotifications(data, token).then(res=>{
                    setStatus(true)
                      
                     
                    })
                  }
                  
                }} >
                        Send
                    </Button>
                    {status && <p>Notification Sent...</p>}
                    </div>
           
                    
            </div>
<div style={{marginTop:20}}/>
            <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">Recent Notifications</h5>
                      <Table
                        dataSource={notificationsDataSource}
                        columns={notificationsColumns}
                      />
                      ;
                    </div>
                  </div>
                </div>
         
          </div>
        </section>
      </main>
   
     
    </div>
  );
};

export default Notifications;
