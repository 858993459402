import { useState } from "react";
import { Modal, Button, Form, Input, message, Upload, InputNumber } from "antd";
import { addPrizes } from "../services";
import useFileUpload from "../hooks/useFileUpload";

export default function FormModal({ modalVisible, updateModal, handleAdd }) {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(modalVisible);
  const [messageApi, contextHolder] = message.useMessage();
  const [token, setToken] = useState(localStorage.getItem('token'))
  const { handleUpload, percent} = useFileUpload();

  const handleFileInputChange = (event) => {
    handleFileUpload(event.target.files[0])
  };

  const handleFileUpload = async (file) => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }
    const {fileUrl} = await handleUpload(file);
    form.setFieldValue('image', fileUrl)
  };

 
  const addPrizeData = () => {
    const { name, description, price, image, amount } = form.getFieldsValue();
    const data = {
      name,
      description,
      image,
      amount,
    };
    addPrizes(data, token)
      .then((res) => {
        if (res.code === "400") {
          messageApi.open({
            type: "error",
            content: `This prize already exists!`,
          });
        } else {
          updateModal(false);
          setIsModalVisible(false);
          handleAdd(name, description, image, amount);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleCancel = () => {
    updateModal(false);
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    form.submit();
    const { name, description, image } = form.getFieldsValue();
    if (name && description && image) {
      addPrizeData();
    }
  };
 
  return (
    <>
      {contextHolder}
      <Modal
        title="Add Prize"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <Form
          labelCol={{ xs: { span: 6 } }}
          wrapperCol={{ xs: { span: 12 } }}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Amount"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="image"
            label="Image"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <>
            <Input
             
              name="image"
              type="file"
              onChange={handleFileInputChange}
              accept="/image/*"
            />
 <p style={{ fontWeight:'bold', color:'black',textAlign:'right' }}>{percent} %</p>

        </>
   
      
          </Form.Item>
         
        </Form>
      </Modal>
    </>
  );
}
