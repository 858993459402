import axios from "axios";

const baseURL = "https://winzco-api.codegeography.com/";
const getToken = localStorage.getItem("token");
const headers = {
  headers: {
    Authorization: `Bearer ${getToken}`,
  },
};
export async function loginAdmin(uid, token) {
  try {
    const response = await axios.get(baseURL + `users/${uid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getProducts(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "products", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}
export async function deleteProdcut(uid, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.delete(baseURL + `products/${uid}`, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function addProduct(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(baseURL + "products", data, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function updateProduct(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(baseURL + "products", data, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

///////////

export async function getPrizes(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "prizes", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}
export async function deletePrizes(uid, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.delete(baseURL + `prizes/${uid}`, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function addPrizes(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(baseURL + "prizes", data, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function updatePrizes(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(baseURL + "prizes", data, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

///////

export async function getCampaignCategories(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "campaign-categories", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}
export async function deleteCampaignCategories(uid, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.delete(
      baseURL + `campaign-categories/${uid}`,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function addCampaignCategories(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(
      baseURL + "campaign-categories",
      data,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function updateCampaignCategories(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(
      baseURL + "campaign-categories",
      data,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

///////

export async function getCurriencies(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "currencies", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}
export async function deleteCurriencies(uid, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.delete(baseURL + `currencies/${uid}`, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function addCurriencies(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(baseURL + "currencies", data, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function updateCurriencies(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(baseURL + "currencies", data, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

///////

export async function getShippingCharges(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "shipping-charges", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}
export async function deleteShippingCharges(uid, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.delete(
      baseURL + `shipping-charges/${uid}`,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function addShippingCharges(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(
      baseURL + "shipping-charges",
      data,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function updateShippingCharges(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(
      baseURL + "shipping-charges",
      data,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

///////

export async function getAllUsers(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "users", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

/////

export async function getCampaigns(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "campaigns", headers);
    return response.data.data;
  } catch (error) {
    return { message: error.response.data.message[0], code: "400" };
  }
}
export async function getCampaignDetails(id, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + `campaigns/${id}`, headers);
    return response.data.data;
  } catch (error) {
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function deleteCampaigns(uid, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.delete(baseURL + `campaigns/${uid}`, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function addCampaigns(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(baseURL + "campaigns", data, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function updateCampaigns(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(baseURL + "campaigns", data, headers);
    return response.data.data;
  } catch (error) {
    return { message: error.response.data.message[0], code: "400" };
  }
}

////

export async function getUpComingDraws(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "draws", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function deleteUpComingDraws(uid, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.delete(baseURL + `draws/${uid}`, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function addUpComingDraws(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(baseURL + "draws", data, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function updateUpComingDraws(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(baseURL + "draws", data, headers);
    return response.data.data;
  } catch (error) {
    return { message: error.response.data.message[0], code: "400" };
  }
}

///

export async function getAnnounceDraws(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "winners", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function createAnnounceDraws(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(
      baseURL + "winners/announce-draw",
      data,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}
/////

export async function getOrders(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "orders", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}
/////

export async function getWinners(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "winners", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

/////

export async function getNotifications(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "notifications", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function generateNotifications(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(baseURL + "notifications", data, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

///

export async function getOrderDetails(id, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + `orders/${id}`, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}
export async function updateOrder(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(
      baseURL + `orders/delivery-status`,
      data,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

///

export async function getUserDetail(id, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + `users/detail/${id}`, headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

///

export async function getDashboard(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + `dashboard`, headers);
    return response.data.data;
  } catch (error) {
    setTimeout(() => {
      if (error.response.status === 401) {
        localStorage.removeItem("userDataLocal");
        window.location.reload(true);
      }
    }, 3000);

    return { message: error.response.data.message[0], code: "400" };
  }
}

///////

export async function getDiscountCoupons(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "discount-coupons", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}
export async function deleteDiscountCoupons(uid, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.delete(
      baseURL + `discount-coupons/${uid}`,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function addDiscountCoupons(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(
      baseURL + "discount-coupons",
      data,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function updateDiscountCoupons(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(
      baseURL + "discount-coupons",
      data,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function getSliders(token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(baseURL + "slider-contents", headers);
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function addSlider(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(
      baseURL + "slider-contents",
      data,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function updateSlider(data, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(
      baseURL + "slider-contents",
      data,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}

export async function deleteSlider(id, token) {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.delete(
      baseURL + `slider-contents/${id}`,
      headers
    );
    return response.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("userDataLocal");
      window.location.reload(true);
    }
    return { message: error.response.data.message[0], code: "400" };
  }
}
