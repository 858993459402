import React,{ useState } from 'react'
import { Modal, Button, Form, Input , message,Select, InputNumber} from 'antd'
import {addCurriencies} from '../services'
export default function FormModal({modalVisible, updateModal, handleAdd}) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(modalVisible)
    const [error, setError] = useState(false)
    const [token, setToken] = useState(localStorage.getItem('token'))

    const addCampignsCategoriesData = () =>{
        form.submit()
        const {name, code, exchangerate,decimalpoints,status} = form.getFieldsValue()
        if(name && code &&  exchangerate && decimalpoints && status){
        const data = {
            
            name,
            code,
            exchangeRate: exchangerate,
            decimalPlaces: decimalpoints,
           status,
        
            
        }
        addCurriencies(data, token).then(res=>{
            if(res.code === "400"){
                messageApi.open({
                    'type':'error',
                    content:`Currency already exist!`
                  })
               
            }
            else{
                updateModal(false)
                setIsModalVisible(false)
                handleAdd(name,
                    code,
                   exchangerate,
                   decimalpoints,
                    status)
               
            }
            
        }).catch((err)=>{
            console.log('err', err)
        })
    }
    }

    const handleCancel = () => {
        updateModal(false)
        setIsModalVisible(false)
        form.resetFields()
    }

    // const handleOk = () => {
    //     setError(false)
    //     form.submit()
    //     const {name, status} = form.getFieldsValue()
    //     if(name&&status){
    //         if(status !== "ACTIVE" && status !== "INACTIVE"){
    //             console.log('Inside if')
    //             setError(true)
               
    //           }else{
    //               console.log('Inside else')
    //         addCampignsCategoriesData()
    //           }
    //     }
    // }


    

    return (
        <>

{contextHolder}

            <Modal
                title="Add Campaign Categories"
                visible={isModalVisible}
                
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={addCampignsCategoriesData}>
                        Submit
                    </Button>,
                ]}
            >
                <Form labelCol={{ xs: { span: 6 } }} wrapperCol={{ xs: { span: 12 } }} form={form}  scrollToFirstError>
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "This field is required." }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="code" label="Code" rules={[{ required: true, message: "This field is required." }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="exchangerate" label="Exchange Rate" rules={[{ required: true, message: "This field is required." }]}>
                    <InputNumber />
                    </Form.Item>
                    <Form.Item name="decimalpoints" label="Decimal Points" rules={[{ required: true, message: "This field is required." }]}>
                        <InputNumber />
                    </Form.Item>

                    <Form.Item name="status" label="Status" rules={[{ required: true, message: "This field is required." }]}>
                    <Select>
                    <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                    <Select.Option value="INACTIVE">INACTIVE</Select.Option>
                    </Select>
                    </Form.Item>

                  
                </Form>
            </Modal>
        </>
    )
}