import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, Popconfirm, Table, Typography, Button,Select } from 'antd';
import FormModal from '../components/FormModalUpcomingDraws'
import FormModalAnnounceDraw from '../components/FormModalAnnounceDraw'
import {getUpComingDraws, deleteUpComingDraws, updateUpComingDraws,getAnnounceDraws, getCampaigns} from '../../src/services'
import moment, { ISO_8601 } from 'moment'
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = title === 'Status' ? (
      <Select>
      <Select.Option value="ACTIVE">ACTIVE</Select.Option>
      <Select.Option value="INACTIVE">INACTIVE</Select.Option>
      </Select>
  ) : <Input/>;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const drawsDataSource = [
    {
      key: "1",
      name: "John",
      coupons: <p>{'123123','213123','564543'}</p>,
      dateTime: '2022-12-12 / 12:00PM',
    },
 
  ];
  
  const drawsColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Coupons",
      dataIndex: "coupons",
      key: "coupons",
    },
    // {
    //   title: "Announcement Date / Time",
    //   dataIndex: "dateTime",
    //   key: "dateTime",
    // },
  ];
const Draws = () => {
  const [modalVisible, setModalVisible] = React.useState(false)
  const [modalVisibleAnnounceDraw, setModalVisibleAnnounceDraw] = React.useState(false)
  const [dataSource, setDataSource] = useState([
   
  ]);
  const [drawsDataSource, setDrawsDataSource] =useState([

  ])

  const [token, setToken] = React.useState(localStorage.getItem('token'))

  React.useEffect( ()=>{
     getUpComingDraws(token).then((res, index)=>{
      setDataSource
      (
        res.map((row,index) => (
          {
            key: row.id,
            campaignId:row.campaignId,
            campaign: row?.campaign?.name,
            status: row?.status,
            date:moment(row?.drawDateTime).format()
            }
        ))
      );
     
    })
     getAnnounceDraws(token).then(res=>{
      
      setDrawsDataSource(
        res.map((row,index) => (
          {
            key: row.id,
            name:row?.coupon?.user?.firstName + ' ' + row?.coupon?.user?.lastName,
            coupons: row?.couponNumber,
            dateTime: moment(row?.drawDateTime).format()
         
            }
        ))
      )
    })
  },[])
  const [count, setCount] = useState(2);
  const handleDelete =async (key) => {
    await deleteUpComingDraws(key,token).then((res)=>{
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);
    })
  };
  const defaultColumns = [
    {
      title: 'Campaign',
      dataIndex: 'campaign',
      width: '30%',
      editable: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      editable: true,
    },
    {
      title: 'Date',
      dataIndex: 'date',
        editable: false,
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
            <div>

<Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
{dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a style={{marginLeft:20, color:'red'}}>Delete</a>
          </Popconfirm>
        ) : null
    }
            </div>
         
        )
       
      }
    },
  ];
  const handleAddWinners=(name, coupons)=>{
    getAnnounceDraws(token).then(res=>{
      
      setDrawsDataSource(
        res.map((row,index) => (
          {
            key: row.id,
            name:row?.coupon?.user?.firstName + ' ' + row?.coupon?.user?.lastName,
            coupons: row?.couponNumber,
            dateTime: moment(row?.drawDateTime).format()
         
            }
        ))
      )
    })
    // const newData = {
    //   key: count,
    //   name: name,
    //   coupons: coupons,
    // };
    // setDrawsDataSource([...dataSource, newData]);
    // setCount(count + 1);
  }
  const handleAdd = (campaign, status, date) => {
    const newData = {
      key: count,
      campaign: campaign,
      status: status,
      date: date
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const updateModal = React.useCallback((param)=>{
    setModalVisible(param)
  },[modalVisible])

  const updateModalAnnounceDraw = React.useCallback((param)=>{
    setModalVisibleAnnounceDraw(param)
  },[modalVisibleAnnounceDraw])

  React.useEffect(()=>{
  },[modalVisible])

  const [form] = Form.useForm();
  const [data, setData] = useState(dataSource);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
       
        const payload = {
          id: newData[index].key,
        
          status:newData[index].status,
          drawDateTime:moment(newData[index].date).toISOString(),
          campaignId: newData[index].campaignId
        }
   
       
           updateUpComingDraws(payload, token).then(res=>{
            setData(newData);
            setDataSource(newData)
            setEditingKey('');
        })
        
        
      } else {
        newData.push(row);
        setData(newData);
        setDataSource(newData)
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
        {modalVisible && <FormModal modalVisible={modalVisible} updateModal={updateModal} handleAdd={handleAdd} />}
        {modalVisibleAnnounceDraw && <FormModalAnnounceDraw modalVisibleAnnounceDraw={modalVisibleAnnounceDraw} updateModalAnnounceDraw={updateModalAnnounceDraw} handleAddWinners={handleAddWinners}  />}

    <main id="main" className="main">

      <section className="section dashboard">
    <div>
      <Button
        onClick={()=> {
            // handleAdd()
            setModalVisible(true)
        }}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Add upcoming draws
      </Button>

      <Button
        onClick={()=> {
           
            setModalVisibleAnnounceDraw(true)
        }}
        type="primary"
        style={{
          marginBottom: 16,
          position:'absolute',
          right:30
        }}
      >
        Announce Draw
      </Button>

      <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={dataSource}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
    <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">Draws</h5>
                      <Table
                        dataSource={drawsDataSource}
                        columns={drawsColumns}
                      />
                      ;
                    </div>
                  </div>
                </div>
    </div>
    </section>
    </main>
    </div>
  );
};
export default Draws;






