import { useState } from 'react'
import { Modal, Button, Form, Input,Checkbox } from 'antd'

export default function FormModal({modalVisible, updateModal}) {
    const [form] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(modalVisible)



    const handleCancel = () => {
        updateModal(false)
        setIsModalVisible(false)
        form.resetFields()
    }

    const handleOk = () => {
        updateModal(false)
        setIsModalVisible(false)
    }

    const onFinish = (data) => {
        updateModal(false)
        setIsModalVisible(false)
    }

    return (
        <>

            <Modal
                title="Order Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Update Status
                    </Button>,
                ]}
            >
                <p style ={{fontWeight:'bold'}}> Order Date: 2020-12-20 / 12:00</p>
                <p style ={{fontWeight:'bold'}}> Total Amount: 3000</p>
                <p style ={{fontWeight:'bold'}}> Payment Type: CASH</p>
                <p style ={{fontWeight:'bold'}}> Delivery Charges: 300</p>
                <p style ={{fontWeight:'bold'}}> Delivery Status: </p>
                Approved : <Checkbox style={{marginLeft:10}} />
                <br/>
                 Processing : <Checkbox style={{marginLeft:10}} />
                 <br/>
                 Completed : <Checkbox style={{marginLeft:10}} />
                 <br/>
               
            
            </Modal>
        </>
    )
}