import React,{ useState } from 'react'
import { Modal, Button, Form, Input,Select, message } from 'antd'
import { getCampaigns,createAnnounceDraws } from '../services';
export default function FormModal({modalVisibleAnnounceDraw, updateModalAnnounceDraw,handleAddWinners}) {
    const [form] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(modalVisibleAnnounceDraw)
    const [campaigns, setCampaigns] = useState([])
    const [token, setToken] = useState(localStorage.getItem('token'))

    const [messageApi, contextHolder] = message.useMessage();
    const addAnnouncedDraws = (campaignId, couponNumber) =>{
       
        const payload ={
            "campaignId": campaignId,
            "couponNumber": couponNumber
        }
        createAnnounceDraws(payload, token).then(res=>{
            console.log('Response...', res.code)
            if(res.code === '400'){
                messageApi.open({
                    'type':'error',
                    content:`Campagin or coupoun number does not exist, Pls try again!`
                  })
               
            }
            else{
        // const newDate = new Date(data.drawDateTime)
        // const cam=campaigns.filter(res=>res.id===data.campaignId)
        handleAddWinners()
        updateModalAnnounceDraw(false)
        setIsModalVisible(false)
        setIsModalVisible(false)
 
            }
        
            
        }).catch((err)=>{
            console.log('err', err)
        })
    }

    React.useEffect(()=>{
        getCampaigns(token).then((res, index)=>{
            setCampaigns(res)
         
        })
      },[])

    const handleCancel = () => {
        updateModalAnnounceDraw(false)
        setIsModalVisible(false)
        form.resetFields()
    }

    const handleOk = () => {
        form.submit()
        const {campaignId, couponNumber} = form.getFieldValue()
        if(campaignId&&couponNumber){
          
            
            addAnnouncedDraws(campaignId,couponNumber)
        }
        console.log('Campaign',campaignId)
        console.log('couponNumber',couponNumber)
    }

    // const onFinish = (data) => {
    //     const newDate = new Date(data.date)
    //     const formattedDate = newDate.getFullYear() + "-" + newDate.getDate() + "-" + Number(newDate.getMonth() + 1) 
    //     updateModalAnnounceDraw(false)
    //     setIsModalVisible(false)
    // }

    return (
        <>
{contextHolder}
            <Modal
                title="Announce Draw"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Announce
                    </Button>,
                ]}
            >
                <Form labelCol={{ xs: { span: 6 } }} wrapperCol={{ xs: { span: 12 } }} form={form}  scrollToFirstError>
                <Form.Item name="campaignId" label="Campaigns" rules={[{ required: true, message: "This field is required." }]}>
                    <Select name="campaignId" placeholder="Please select a campigns">
              {campaigns.map(res=>{
                  return(
              <Select.Option value={res.id}>{res.name}</Select.Option>
                  )
              })}
   
            </Select>
                    </Form.Item>

                    <Form.Item name="couponNumber" label="Coupon Number" rules={[{ required: true, message: "This field is required." }]}>
                        <Input
                         />
                    </Form.Item>


                   
                  
                </Form>
            </Modal>
        </>
    )
}