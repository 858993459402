import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  Popconfirm,
  Table,
  Typography,
  Button,
  Upload,
} from "antd";
import FormModal from "../components/FormModalSliders";
import moment from "moment";
import {
  getSliders,
  getCampaigns,
  addSlider,
  updateSlider,
  deleteSlider,
} from "../../src/services";
import { useNavigate } from "react-router-dom";
import useFileUpload from "../hooks/useFileUpload";
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const Sliders = () => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const navigate = useNavigate();
  const [count, setCount] = useState(2);
  const [uploaded, setUploaded] = useState(false);
  const [token, getToken] = useState(localStorage.getItem('token'))
  const { handleUpload, fileUrl, percent } = useFileUpload();
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      dataIndex === "campaign" ? (
        <Select
          placeholder="Select Campaigns"
          name="campaign"
          onChange={(e) => form.setFieldValue(e)}
        >
          {campaigns.map((campaign) => {
            return (
              <Select.Option value={campaign.id}>{campaign.name}</Select.Option>
            );
          })}
        </Select>
      ) : (
        <Upload
          beforeUpload={() => false}
          onChange={async (e) => {
            setUploaded(false);
            const response = await handleUpload(e.file);

            record.image = (
              <img
                src={fileUrl ? fileUrl : response.fileUrl}
                height="80"
                width="80"
              />
            );
            setUploaded(true);
          }}
        >
          <Button type="primary">New Image</Button>
          {uploaded && <p>{"Uploaded, Please save"}</p>}
        </Upload>
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  React.useEffect(() => {
    getCampaigns(token).then((response) => {
      console.log(response);
      setCampaigns(
        response.map((responseCampaign) => {
          return {
            key: responseCampaign.id,
            id: responseCampaign.id,
            name: responseCampaign.name,
          };
        })
      );
    });
    getSliders(token).then((response) => {
      setDataSource(
        response.map((sliderResponse) => {
          return {
            key: sliderResponse.id,
            image: <img width={80} height={80} src={sliderResponse.url} />,
            campaign: sliderResponse.campaign.name,
          };
        })
      );
    });
  }, []);
  console.log(campaigns);
  console.log(dataSource);
  const handleDelete = async (key) => {
    await deleteSlider(key, token).then((res) => {
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);
    });
  };
  const defaultColumns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      editable: true,
    },
    {
      title: "Campaign",
      key: "campaign",
      dataIndex: "campaign",
      editable: true,
    },
    {
      title: "Actions",
      key: "actions",
      width: "20%",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div>
            {/* <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link> */}
            {dataSource.length >= 1 ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.key)}
              >
                <a style={{ marginLeft: 10, color: "red" }}>Delete</a>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];
  const handleAdd = (image, campaign) => {
    const newData = {
      image: <img width={80} height={80} src={image} />,
      campaign: campaign,
    };
    setDataSource([...dataSource, newData]);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const updateModal = React.useCallback(
    (param) => {
      setModalVisible(param);
    },
    [modalVisible]
  );

  React.useEffect(() => {}, [modalVisible]);

  const [form] = Form.useForm();
  const [data, setData] = useState(dataSource);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        const payload = {
          id: newData[index].key,
          url: newData[index]?.image,
          campaignId: newData[index]?.campaign,
        };

        newData[index].campaign = campaigns.filter(
          (campaign) => campaign.id == newData[index]?.campaign
        )[0]?.name;

        updateSlider(payload, token).then((res) => {
          setData(newData);
          setDataSource(newData);
          setEditingKey("");
        });
      } else {
        newData.push(row);
        setData(newData);
        setDataSource(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      {modalVisible && (
        <FormModal
          modalVisible={modalVisible}
          updateModal={updateModal}
          handleAdd={handleAdd}
          campaigns={campaigns}
          count={count}
          setCount={setCount}
        />
      )}
      <main id="main" className="main">
        <section className="section dashboard">
          <div>
            <Button
              onClick={() => {
                // handleAdd()
                setModalVisible(true);
              }}
              type="primary"
              style={{
                marginBottom: 16,
              }}
            >
              Add new Slider
            </Button>

            <Form form={form} component={false}>
              <Table
                size="small"
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={dataSource}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }}
              />
            </Form>
          </div>
        </section>
      </main>
    </div>
  );
};
export default Sliders;
