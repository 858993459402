import React,{ useState } from 'react'
import { Modal, Button, Form, Input,Upload ,message, InputNumber} from 'antd';
import {addProduct} from '../services'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../registerFirebase';
import useFileUpload from "../hooks/useFileUpload";



export default function FormModal({modalVisible, updateModal, handleAdd}) {
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage();
    const [error, setError] = useState(false)
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [isModalVisible, setIsModalVisible] = useState(modalVisible)
    const { handleUpload, percent} = useFileUpload();    

    const handleFileInputChange = (event) => {
        handleFileUpload(event.target.files[0])
      };
    
      const handleFileUpload = async (file) => {
        if (!file) {
          alert("Please select a file first.");
          return;
        }
        const {fileUrl} = await handleUpload(file);
        form.setFieldValue('image', fileUrl)
      };
 
       
    const addProducts = () =>{
       
        const {name, description, image, price} = form.getFieldsValue()
        const data = {
            
            name,
           description,
            image: image,
            price: Number(price)
        }
        addProduct(data, token).then(res=>{
            if(res.code === "400"){
                messageApi.open({
                    'type':'error',
                    content:`This product already exists!`
                  })
               
            }
            else{
                updateModal(false)
                setIsModalVisible(false)
 handleAdd(name, description, image, price)
            }
        
            
        }).catch((err)=>{
            console.log('err', err)
        })
    }

    const handleCancel = () => {
        updateModal(false)
        setIsModalVisible(false)
        form.resetFields()
    }

    const handleOk = () => {
        form.submit()
        const {name, description, image, price} = form.getFieldsValue()
      
        if(name&&description&&price&&image){
            addProducts()
        }
       
    }



    return (
        <>
   {contextHolder}
            <Modal
                title="Add Product"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form labelCol={{ xs: { span: 6 } }} wrapperCol={{ xs: { span: 12 } }} form={form} scrollToFirstError>
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "This field is required." }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="description" label="Description" rules={[{ required: true, message: "This field is required." }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="price" label="Price" rules={[{ required: true, message: "This field is required." }]}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item  rules={[{ required: true, message: "This field is required." }]} name="image" label='Image'> 
                    
            <Input style={{position:'absolute', top:0}}  name="image"  type="file" onChange={handleFileInputChange} accept="/image/*" />
            
       <p style={{marginTop:20, fontWeight:'bold'}}>{percent} %</p>
                    </Form.Item>
                 
                </Form>
            </Modal>
        </>
    )
}