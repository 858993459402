import React, { useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Switch,
} from "react-router-dom";
import Dashboard from "./routes/Dashboard";
import Users from "./routes/Users";
import UsersDetails from "./routes/UsersDetails";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import Footer from "./components/Footer";
import Porducts from "./routes/Products";
import CampaignCategories from "./routes/CampaignCategories";
import Prize from "./routes/Prize";
import Draws from "./routes/Draws";
import Winners from "./routes/Winners";
import Coupons from "./routes/Coupons";
import Notifications from "./routes/Notifications";
import MultiCurrency from "./routes/MultiCurrency";
import ShippingCharges from "./routes/ShippingCharges";
import Login from "./routes/Login";
import Orders from "./routes/Orders";
import Campaigns from "./routes/Campaigns";
import CampaignsDetails from "./routes/CampaignsDetails";
import OrderDetail from "./routes/OrderDetail";
import { UserProvider, useUser } from "./context/UserContext";
import Sliders from "./routes/Sliders";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "users/",
    element: <Users />,
  },
  {
    path: "usersDetail/",
    element: <UsersDetails />,
  },
  {
    path: "products/",
    element: <Porducts />,
  },
  {
    path: "campaignCategories/",
    element: <CampaignCategories />,
  },
  {
    path: "prize/",
    element: <Prize />,
  },
  {
    path: "draws/",
    element: <Draws />,
  },
  {
    path: "winners/",
    element: <Winners />,
  },
  {
    path: "coupons/",
    element: <Coupons />,
  },
  {
    path: "notifications/",
    element: <Notifications />,
  },
  {
    path: "currency/",
    element: <MultiCurrency />,
  },
  {
    path: "shipping/",
    element: <ShippingCharges />,
  },
  // {
  //   path: "login/",
  //   element: <Login />,
  // },
  {
    path: "orders/",
    element: <Orders />,
  },
  {
    path: "orderDetail/",
    element: <OrderDetail />,
  },
  {
    path: "campaigns/",
    element: <Campaigns />,
  },
  {
    path: "campaignsDetails/",
    element: <CampaignsDetails />,
  },
  {
    path: "sliders/",
    element: <Sliders />,
  },
]);

const Layout = () => {
  const [loading, setLoading] = useState(true);
  const { userData, addDataToUser } = useUser();
  const [userDataLocal, setUserDataLocal] = React.useState([]);
  React.useEffect(() => {
    const data = localStorage.getItem("userDataLocal");
    const parsedData = JSON.parse(data);
    setUserDataLocal(parsedData ? [...userDataLocal, parsedData] : []);
    setLoading(false);
  }, [userData]);
  return (
    <>
      {!loading && (
        <>
          {userDataLocal.length === 0 ? (
            <Login />
          ) : (
            <>
              <Header />
              <SideBar />
              <RouterProvider router={router} />
            </>
          )}
        </>
      )}
    </>
  );
};

function App() {
  return (
    <UserProvider>
      <React.StrictMode>
        <>
          <Layout />
        </>
      </React.StrictMode>
    </UserProvider>
  );
}
export default App;
