import React,{ useState } from 'react'
import { Modal, Button, Form, Input , message,Select} from 'antd'
import {addCampaignCategories} from '../services'
export default function FormModal({modalVisible, updateModal, handleAdd}) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(modalVisible)
    const [error, setError] = useState(false)
    const [token, setToken] = useState(localStorage.getItem('token'))

    const addCampignsCategoriesData = () =>{
        const {name, status} = form.getFieldsValue()
        const data = {
            
            name,
           status,
        
            
        }
        addCampaignCategories(data, token).then(res=>{
            if(res.code === "400"){
                messageApi.open({
                    'type':'error',
                    content:`Campaign already exist!`
                  })
               
            }
            else{
                updateModal(false)
                setIsModalVisible(false)
         
                handleAdd(name, status)
            }
            
        }).catch((err)=>{
            console.log('err', err)
        })
    }

    const handleCancel = () => {
        updateModal(false)
        setIsModalVisible(false)
        form.resetFields()
    }

  

    const onFinish = (data) => {
        if(error){
            messageApi.open({
                'type':'error',
                content:`Status should be "ACTIVE" OR "INACTIVE"`
              })
        }else{
        updateModal(false)
        setIsModalVisible(false)
        }
    }

    return (
        <>

{contextHolder}

            <Modal
                title="Add Campaign Categories"
                visible={isModalVisible}
                
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={addCampignsCategoriesData}>
                        Submit
                    </Button>,
                ]}
            >
                <Form labelCol={{ xs: { span: 6 } }} wrapperCol={{ xs: { span: 12 } }} form={form} onFinish={onFinish} scrollToFirstError>
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "This field is required." }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="status" label="Status" rules={[{ required: true, message: "This field is required." }]}>
                    <Select>
                    <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                    <Select.Option value="INACTIVE">INACTIVE</Select.Option>
                    </Select>
                    </Form.Item>

                  
                </Form>
            </Modal>
        </>
    )
}