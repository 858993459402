import { useState } from 'react'
import { Modal, Button, Form, Input,Select,message } from 'antd'
import { addDiscountCoupons } from '../services';

export default function FormModal({modalVisible, updateModal, handleAdd}) {
    const [form] = Form.useForm()
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [isModalVisible, setIsModalVisible] = useState(modalVisible)
    const [messageApi, contextHolder] = message.useMessage();


    const handleCancel = () => {
        updateModal(false)
        setIsModalVisible(false)
        form.resetFields()
    }

  


    const addCoupon = () =>{
        const {name, code, discounttype, value, status} = form.getFieldsValue()
        const data = {
            
            name,
            code,
            "discountType":discounttype,
            value:+value,
            status
            
        }
        addDiscountCoupons(data, token).then(res=>{
            if(res.code === "400"){
                messageApi.open({
                    'type':'error',
                    content:`Discount Coupon already exists!`
                  })
               
            }
            else{
                updateModal(false)
                setIsModalVisible(false)
                handleAdd(name, code, discounttype, value, status)
             
            }
            
        }).catch((err)=>{
            console.log('err', err)
        })
    }

    const handleOk = () => {
        form.submit()
        const {name, code, discounttype, value, status} = form.getFieldsValue()
        if(name && code && discounttype && value&& status){
            addCoupon()
        }
    }

    return (
        <>
 {contextHolder}
            <Modal
                title="Add Discount Coupon"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form labelCol={{ xs: { span: 6 } }} wrapperCol={{ xs: { span: 12 } }} form={form}  scrollToFirstError>
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "This field is required." }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="code" label="Code" rules={[{ required: true, message: "This field is required." }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="discounttype" label="Discount Type" rules={[{ required: true, message: "This field is required.", }]}>
                    <Select>
                    <Select.Option value="PERCENTAGE">PERCENTAGE</Select.Option>
                    <Select.Option value="FIAT">FIAT</Select.Option>
                    </Select>
                    </Form.Item>
                    <Form.Item name="value" label="Value" rules={[{ required: true, message: "This field is required." }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="status" label="Status" rules={[{ required: true, message: "This field is required." }]}>
                    <Select>
                    <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                    <Select.Option value="INACTIVE">INACTIVE</Select.Option>
                    </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}