import React,{ useState } from 'react'
import { Modal, Button, Form, Input,Select, DatePicker,message } from 'antd'
import { addUpComingDraws,getCampaigns } from '../services';
import moment from 'moment'
export default function FormModal({modalVisible, updateModal, handleAdd}) {
    const [form] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(modalVisible)
    const [campaigns, setCampaigns] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [token, setToken] = useState(localStorage.getItem('token'))
    const addUpcoming = (data) =>{
       
        
        addUpComingDraws(data, token).then(res=>{
            if(res.code === "400"){
                messageApi.open({
                    'type':'error',
                    content:`This already exist!`
                  })
               
            }
            else{
        const newDate = new Date(data.drawDateTime)
        const cam=campaigns.filter(res=>res.id===data.campaignId)
        handleAdd(cam[0].name, data.status, moment(newDate).format())
        updateModal(false)
        setIsModalVisible(false)
 
            }
        
            
        }).catch((err)=>{
            console.log('err', err)
        })
    }

    const handleCancel = () => {
        updateModal(false)
        setIsModalVisible(false)
        form.resetFields()
    }

    const handleOk = () => {
        form.submit()
        const {drawDateTime, campaignId, status} = form.getFieldValue()
        if(drawDateTime, campaignId, status){
            const data={
                drawDateTime,
                campaignId,
                status
            }
            addUpcoming(data)
        }
       
    }

    React.useEffect(()=>{
        getCampaigns(token).then((res, index)=>{
            setCampaigns(res)
         
        })
      },[])

    // const onFinish = (data) => {
    //     const newDate = new Date(data.date)
    //     const formattedDate = newDate.getFullYear() + "-" + newDate.getDate() + "-" + Number(newDate.getMonth() + 1) 
    //     handleAdd(data.campaigns, data.status, formattedDate)
    //     updateModal(false)
    //     setIsModalVisible(false)
    // }

    return (
        <>
{contextHolder}
            <Modal
                title="Add Upcoming Draws"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Submit
                    </Button>,
                ]}
            >
                <Form labelCol={{ xs: { span: 6 } }} wrapperCol={{ xs: { span: 12 } }} form={form}  scrollToFirstError>
                    <Form.Item name="campaignId" label="Campaigns" rules={[{ required: true, message: "This field is required." }]}>
                    <Select name="campaignId" placeholder="Please select a campigns">
              {campaigns.map(res=>{
                  return(
              <Select.Option value={res.id}>{res.name}</Select.Option>
                  )
              })}
   
            </Select>
                    </Form.Item>

                    <Form.Item name="status" label="Status" rules={[{ required: true, message: "This field is required." }]}>
                    
                <Select>
                <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                <Select.Option value="INACTIVE">INACTIVE</Select.Option>
                </Select>
            
                    </Form.Item>


                    <Form.Item name="drawDateTime" label="Date" rules={[{ required: true, message: "This field is required." }]}>
                        <DatePicker />
                    </Form.Item>
                  
                </Form>
            </Modal>
        </>
    )
}