import React from "react";
import "../App.css";

const Header = () => {
  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <a href="index.html" className="logo d-flex align-items-center">
          <span className="d-none d-lg-block">Winzco</span>
        </a>
        {/* <i className="bi bi-list toggle-sidebar-btn" /> */}
      </div>
    </header>
  );
};

export default Header;
