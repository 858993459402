import { useState } from 'react'
import { Modal, Button, Form, Input } from 'antd'

export default function FormModal({modalVisible, updateModal,data}) {
    const [form] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(modalVisible)



    const handleCancel = () => {
        updateModal(false)
        setIsModalVisible(false)
        form.resetFields()
    }

    const handleOk = () => {
        updateModal(false)
        setIsModalVisible(false)
    }

    const onFinish = (data) => {
        updateModal(false)
        setIsModalVisible(false)
    }

    return (
        console.log('data',data)||
        <>

            <Modal
                title="Winner Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Okay
                    </Button>,
                ]}
            >
                <p style ={{fontWeight:'bold'}}> Name: {data?.coupon?.user?.firstName + ' ' + data?.coupon?.user?.lastName}</p>
                <p style ={{fontWeight:'bold'}}> Email: {data?.coupon?.user?.email || '-'}</p>
                <p style ={{fontWeight:'bold'}}> Mobile Number: {data?.coupon?.user?.phone || '-'}</p>
                <p style ={{fontWeight:'bold'}}> Prize: {data?.campaign?.prize?.amount || '-'}</p>
                <p style ={{fontWeight:'bold'}}> Claim Option: Prize</p>
              
            </Modal>
        </>
    )
}