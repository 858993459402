import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, Popconfirm, Table, Typography, Button, Upload } from 'antd';
import FormModal from '../components/FormModalProduct'
import {getProducts,deleteProdcut,updateProduct} from '../../src/services'
import useFileUpload from "../hooks/useFileUpload";
const EditableContext = React.createContext(null);


const Products = () => {
  const { handleUpload, fileUrl,percent} = useFileUpload();
  const [modalVisible, setModalVisible] = React.useState(false)
  const [dataSource, setDataSource] = useState([]);
  const [uploaded, setUploaded] = React.useState(false)
  const [count, setCount] = useState(2);
  const [token, setToken] = React.useState(localStorage.getItem('token'))
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
  
    var inputNode = "";
    if (inputType === "number") {
      inputNode = <InputNumber />;
    } else if (dataIndex === "image") {
     
      inputNode = (
        <Upload
          beforeUpload={() => false}
          onChange={async(e) => {
            setUploaded(false)
            const response = await handleUpload(e.file);
            
            record.imageUrl = fileUrl ? fileUrl : response.fileUrl
            record.image = <img src={fileUrl ? fileUrl : response.fileUrl} height="80" width="80" />
            setUploaded(true)
          }}
        > 
            <Button type="primary">New Image</Button>
            {uploaded && <p>{'Uploaded, Pleae save'}</p>}
        </Upload>
      );
    } else {
      inputNode = <Input />;
    }
  
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  React.useEffect(()=>{
    getProducts(token).then((res, index)=>{
      setDataSource
      (
        res.map((row,index) => ({
          key: row.id,
          name: row.name,
          imageUrl: row.image,
          image: <img src={row.image} height= "80" width = "80" />,
          description: row.description,
          price:row.price
        }))
      );
     
    })
  },[])
  const handleDelete = async (key) => {
   
    await deleteProdcut(key, token).then((res)=>{
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);

    })

  
  };
  const defaultColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%',
      editable: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: 'Image',
      dataIndex: 'image',
        editable: true,
    },
    {
        title: 'Price',
        dataIndex: 'price',
        editable: true,
      },
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
            <div>

<Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
{dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a style={{marginLeft:20, color:'red'}}>Delete</a>
          </Popconfirm>
        ) : null
    }
            </div>
         
        )
       
      }
    },
  ];
  const handleAdd = (name, description, image, price) => {
    const newData = {
      key: count,
      name: name,
      description: description,
      image: <img src={image} height= "80" width = "80" alt="No Image" />,
      price: price,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const updateModal = React.useCallback((param)=>{
    setModalVisible(param)
  },[modalVisible])

  React.useEffect(()=>{
  },[modalVisible])

  const [form] = Form.useForm();
  const [data, setData] = useState(dataSource);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setUploaded(false)
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        newData[index].image = <img height="80" width="80" src={newData[index].imageUrl} />
        setData(newData);
        setDataSource(newData)
        setEditingKey('');
        console.log('Data', newData)
        const payload = {
          id: newData[index].key,
          name:newData[index].name,
          description:newData[index].description,
          image: newData[index].imageUrl,
          price: Number(newData[index].price)
        }
        
        updateProduct(payload, token)
      } else {
     
        newData.push(row);
        setData(newData);
        setDataSource(newData)
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
        {modalVisible && <FormModal modalVisible={modalVisible} updateModal={updateModal} handleAdd={handleAdd} />}
    <main id="main" className="main">

      <section className="section dashboard">
    <div>
      <Button
        onClick={()=> {
            // handleAdd()
            setModalVisible(true)
        }}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Add new product
      </Button>

      <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={dataSource}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
      {/* <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={}
        columns={columns}
      /> */}
    </div>
    </section>
    </main>
    </div>
  );
};
export default Products;






