import React from "react";
import { Table,Select, Button,message } from "antd";
import "../App.css";
import { useLocation } from "react-router-dom";
import {updateOrder} from '../services'
import moment from 'moment'


  

  const customerColumns = [
    {
      title: "Customer Name",
      dataIndex: "customername",
      key: "customername",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone number",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
        title: "Address",
        dataIndex: "address",
        key: "address",
      },

      {
        title: "City",
        dataIndex: "city",
        key: "city",
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
      },
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
      },
    
     
  ];
  
  const productsColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
      },
    
     
  ];

const campaignsColumns = [
  {
    title: "Order Number",
    dataIndex: "ordernumber",
    key: "ordernumber",
  },
  {
    title: "Order Date and Time",
    dataIndex: "orderdatetime",
    key: "orderdatetime",
  },
  {
    title: "Customer Name",
    dataIndex: "customername",
    key: "orderamount",
  },
  {
    title: "Payment Type",
    dataIndex: "paymenttype",
    key: "paymenttype",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
  },
];



const OrderDetail = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [token, setToken] = React.useState(localStorage.getItem('token'))
  const location =useLocation()
  const customerDataSource = [
    {
      key: location?.state?.data?.id,
      customername: location?.state?.data?.user?.firstName + ' ' +location?.state?.data?.user?.lastName,
      email: location?.state?.data?.user?.email,
      phonenumber:location?.state?.data?.user?.phone,
      address:location?.state?.data?.address?.street+ ' ' + location?.state?.data?.address?.city +' '+location?.state?.data?.address?.country,
      city:location?.state?.data?.address?.city,
      state:location?.state?.data?.address?.street,
      country:location?.state?.data?.address?.country
    },
   
  ];
  const [productsDataSource, setProductDataSource] =React.useState([
  

  
  ])
  const [orderStatus, setOrderStatus] = React.useState(location?.state?.data?.deliveryStatus)
  const [updatedStatus, setUpdatedStatus] = React.useState('')
  React.useEffect(()=>{
    setProductDataSource(
      location.state.data.orderItems.map((res)=> {return ({
      
        key: res.id,
         name: res?.campaign?.product?.name,
    image: (
      <img
        style={{ height: 80, width: 80 }}
        src={res?.campaign?.product?.image}
      ></img>
    ),
    price: <p style={{ fontWeight: "bold" }}>{res?.campaign?.product?.price} AED</p>,
    qty:res.quantity,
    donate:'Yes',
    noofcoupons:'170',
    purchaseddate:'2020-09-01'
        
      })
      })
    )
    
  },[location?.state?.data?.orderItems])
 

 
 
  
  const onSubmit = () =>{
    const data={
      "orderId": location?.state?.data?.id,
      "deliveryStatus":orderStatus || 'INPROGRESS'
    }
    updateOrder(data, token).then(res=>{
      if(res.code === "400"){
        messageApi.open({
            'type':'error',
            content:`Something went wrong Please try again`
          })
       
    }else{
      setUpdatedStatus(orderStatus)
      messageApi.open({
        'type':'success',
        content:`Status changed successfully`
      })
    }
    })

  }
    const handleChange = React.useCallback((params) =>{
      setOrderStatus(params)
    
    },[])
  return (
    console.log('props',location.state.data.orderItems)||
    <div>
      {contextHolder}
      <main id="main" className="main">
        {/* End Page Title */}
        <section className="section dashboard">
          <div className="row">
            {/* Left side columns */}
            <div className="col-lg-12">
              <div className="row">
              <div className="col-xxl-4 col-md-6">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Order Details</h5>
                      <div >
                          
                      <p style ={{fontWeight:'bold'}}> Order Date: {moment(location?.state?.data?.createdAt).format()}</p>
                <p style ={{fontWeight:'bold'}}> Total Amount: {location?.state?.data?.totalAmount}</p>
                <p style ={{fontWeight:'bold'}}> Payment Type: CASH</p>
                <p style ={{fontWeight:'bold'}}> Delivery Charges: {location?.state?.data?.vat}</p>
                <div style={{flexDirection:'row'}}>
                <p style ={{fontWeight:'bold'}}> Delivery Status: {updatedStatus} </p>
                <Select
      defaultValue={orderStatus}
      style={{
        width: 120,
      }}
      onChange={handleChange}
      options={[
        {
          value: 'INPROGRESS',
          label: 'In Progress',
        },
        {
          value: 'APPROVED',
          label: 'Approved',
        },
        {
          value: 'REJECTED',
          label: 'Rejected',
        },
        {
          value: 'SHIPPED',
          label: 'Shipped',
        },
      ]}
    />
    <Button  type="primary" className="flex-end" style={{marginLeft:10}} onClick={()=>onSubmit()} >
                        Update
                    </Button>
    </div>
               
                        
                        
                       
                     
                      </div>
                    </div>
                  </div>
                  
                </div>
               

        

                <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title"> Products</h5>
                      <Table
                        dataSource={productsDataSource}
                        columns={productsColumns}
                      />
                      
                    </div>
                    <p style={{position:'absolute', right:0, bottom:0, left:0, marginLeft:20, fontWeight:'bold'}}>Total amount : {location?.state?.data?.totalAmount} AED</p>
                  </div>
                
                </div>
                <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title"> Customer Address(Shipping)</h5>
                      <Table
                        dataSource={customerDataSource}
                        columns={customerColumns}
                      />
                      
                    </div>
                  </div>
                
                </div>

                {/* End Top Selling */}
              </div>
            </div>
          
          </div>
        </section>
      </main>
   
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
      {/* Vendor JS Files */}
      {/* Template Main JS File */}
    </div>
  );
};

export default OrderDetail;
