import React, { useState } from "react";
import { Modal, Button, Form, Input, message, Select } from "antd";
import { addSlider } from "../services/index";
import useFileUpload from "../hooks/useFileUpload";

export default function FormModal({
  modalVisible,
  updateModal,
  handleAdd,
  campaigns,
  count,
  setCount,
}) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(modalVisible);
  const [token, setToken] = useState(localStorage.getItem('token'))
  const { handleUpload, percent } = useFileUpload();
  const handleFileInputChange = (event) => {
    handleFileUpload(event.target.files[0]);
  };

  const handleFileUpload = async (file) => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }
    const { fileUrl } = await handleUpload(file);
    form.setFieldValue("image", fileUrl);
  };

  const addSliders = () => {
    console.log(form.getFieldsValue());
    const { image, campaign } = form.getFieldsValue();
    const data = {
      id: count,
      url: image,
      campaignId: campaign,
    };
    console.log(data);

    addSlider(data, token)
      .then((res) => {
        if (res.code === "400") {
          messageApi.open({
            type: "error",
            content: `This slider already exists!`,
          });
        } else {
          updateModal(false);
          setIsModalVisible(false);
          handleAdd(
            image,
            campaigns.filter((camp) => camp.id == campaign)[0].name
          );
        }
        setCount((prevCount) => prevCount + 1);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleCancel = () => {
    updateModal(false);
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    form.submit();
    const { image, campaign } = form.getFieldsValue();

    if (image && campaign) {
      addSliders();
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Add Slider"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <Form
          labelCol={{ xs: { span: 6 } }}
          wrapperCol={{ xs: { span: 12 } }}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="campaign"
            label="Campaign"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Select
              placeholder="Select Campaigns"
              name="campaign"
              onChange={(e) => form.setFieldValue("campaign", e)}
            >
              {campaigns.map((campaign) => {
                return (
                  <Select.Option value={campaign.id}>
                    {campaign.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "This field is required." }]}
            name="image"
            label="Image"
          >
            <>
              <Input
                name="image"
                type="file"
                onChange={handleFileInputChange}
                accept="/image/*"
              />
              <p style={{ marginTop: 20, fontWeight: "bold" }}>{percent} %</p>
            </>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
