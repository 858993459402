import React from "react";
import { Table } from "antd";
import "../App.css";
import OrderDetailModal from '../components/OrderDetailModal'
import { getOrders,getOrderDetails } from "../services";
import { useNavigate } from "react-router-dom";
import moment from 'moment'


const orderColumns = [
  {
    title: "Order Number",
    dataIndex: "ordernumber",
    key: "ordernumber",
  },
  {
    title: "Order Date and Time",
    dataIndex: "orderdatetime",
    key: "orderdatetime",
  },
  {
    title: "Customer Name",
    dataIndex: "customername",
    key: "customername",
  },
  {
    title: "Payment Type",
    dataIndex: "paymenttype",
    key: "paymenttype",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
  },
];



const Orders = (props) => {
  let navigate = useNavigate();
  const [token, setToken] = React.useState(localStorage.getItem('token'))
    const [modalVisible, setModalVisible] = React.useState(false)
    const [dataSource, setDataSource] = React.useState([
       
        
      ])

      const details = (id)=>{
        console.log('Props',props)
    
        getOrderDetails(id, token).then(res=>{
          navigate('/orderDetail',{
            state: {
                data: res
            },
        })
        })
      }

      React.useEffect(()=>{
        getOrders(token).then((res, index)=>{
          setDataSource
          (
            res.map((row,index) => ({
              
                key: row.id,
                ordernumber: row.id,
                orderdatetime: moment(res.createdAt).format(),
                customername:row?.user?.firstName +' ' + row?.user?.lastName,
                paymenttype:'Cash',
                status:row?.deliveryStatus,
                details:<div > <a onClick={()=>details(row.id)} className="nav-link " ><p style={{color:'blue', fontWeight:'bold', textDecoration:'underline', marginTop:'20px', cursor:'pointer'}}>Details</p></a></div>
            
            }))
          );
         
        })
      },[token])
    const updateModal = React.useCallback((params) =>{
        setModalVisible(params)
    },[modalVisible])
  return (
    <div>
        {modalVisible && <OrderDetailModal updateModal={updateModal} modalVisible = {modalVisible}/>}
      <main id="main" className="main">
        {/* End Page Title */}
        <section className="section dashboard">
          <div className="row">
            {/* Left side columns */}
            <div className="col-lg-12">
              <div className="row">
             
               

                <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">Orders</h5>
                      <Table
                        dataSource={dataSource}
                        columns={orderColumns}
                      />
                      
                    </div>
                  </div>
                </div>

              

                {/* End Top Selling */}
              </div>
            </div>
          
          </div>
        </section>
      </main>
   
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
      {/* Vendor JS Files */}
      {/* Template Main JS File */}
    </div>
  );
};

export default Orders;
