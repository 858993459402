import React, { useState } from "react";
import { Table } from "antd";
import "../App.css";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
const productsDataSource1 = [
  {
    key: "1",
    name: "John",
    image: (
      <img
        style={{ height: 80, width: 80 }}
        src="https://picsum.photos/200/300"
      ></img>
    ),
    price: <p style={{ fontWeight: "bold" }}>20 AED</p>,
    qty: 20,
    donate: "Yes",
    noofcoupons: "170",
    purchaseddate: "2020-09-01",
    email: "das@yopmail.com",
    mobilenumber: "09912312",
    winner: "dan",
    claimedprize: "Yes",
    claimedvoucher: "Test",
  },
];

const productsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "image",
  },
  {
    title: "Mobile Number",
    dataIndex: "mobilenumber",
    key: "mobilenumber",
  },
  {
    title: "Purchased Date",
    dataIndex: "purchaseddate",
    key: "purchaseddate",
  },
  {
    title: "Winner",
    dataIndex: "winner",
    key: "winner",
  },

  {
    title: "Claim Option",
    dataIndex: "claimoption",
    key: "claimoption",
  },
];

const CampaignsDetails = () => {
  const location = useLocation();
  console.log(location?.state);
  const campaignDetails = [
    {
      campaignId: location?.state?.data?.campaign?.id,
      participatedUsers: location?.state?.data?.noOfUsers,
      totalWinners: location?.state?.data?.noOfWinners,
      productImage: location?.state?.data?.campaign?.product?.image,
      productPrice: location?.state?.data?.campaign?.product?.price,
      drawDate: location?.state?.data?.campaign?.draws[0]?.drawDateTime,
    },
  ];

  const productsDataSource = location?.state?.data?.campaign?.coupons?.map(
    (coupon) => {
      return {
        name: coupon?.user?.firstName + " " + coupon?.user?.lastName,
        email: coupon?.user?.email,
        mobilenumber: coupon?.user?.phone,
        purchaseddate: moment(coupon?.createdAt)?.format("YYYY-M-D"),
        winner: coupon?.winners?.length,
        claimoption: coupon?.winners[0]?.claimOption,
      };
    }
  );

  return (
    <div>
      <main id="main" className="main">
        {/* End Page Title */}
        <section className="section dashboard">
          <div className="row">
            {/* Left side columns */}
            <div className="col-lg-12">
              {campaignDetails.map((detail) => {
                let momentDate = moment(detail.drawDate);
                let drawDate = momentDate.format("YYYY-M-D");
                return (
                  <div className="row">
                    <div className="col-xxl-4 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">Campaigns Details</h5>
                          <div>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <p style={{ fontWeight: "bold", marginTop: 10 }}>
                                Image:{" "}
                              </p>

                              <img
                                style={{
                                  height: 50,
                                  width: 50,
                                  marginLeft: 20,
                                  borderRadius: 100,
                                }}
                                src={detail.productImage}
                              />
                            </div>

                            <div
                              style={{
                                width: "100%",
                                height: 1,
                                backgroundColor: "black",
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            />

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 15,
                              }}
                            >
                              <p style={{ fontWeight: "bold" }}>
                                Sold out:{" "}
                                <span style={{ fontWeight: "normal" }}>
                                  {detail.productPrice}
                                </span>{" "}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                height: 1,
                                backgroundColor: "black",
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            />

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 15,
                              }}
                            >
                              <p style={{ fontWeight: "bold" }}>
                                Draw Date:{" "}
                                <span style={{ fontWeight: "normal" }}>
                                  {/* 2020-12-09 */}
                                  {drawDate}
                                </span>{" "}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                height: 1,
                                backgroundColor: "black",
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">Participated User</h5>
                          <p style={{ fontWeight: "bold", marginTop: 10 }}>
                            {detail.participatedUsers}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-md-6">
                      <div className="card info-card sales-card">
                        <div className="card-body">
                          <h5 className="card-title">Number of Winners</h5>

                          <p style={{ fontWeight: "bold", marginTop: 10 }}>
                            {detail.participatedUsers}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">Coupon Purchased Table</h5>
                      <Table
                        dataSource={productsDataSource}
                        columns={productsColumns}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default CampaignsDetails;
