const config = {
  apiKey: "AIzaSyBXa4BdOtgR64rX92pH1op-OzZd278I2RI",
  authDomain: "winzco-7b87b.firebaseapp.com",
  projectId: "winzco-7b87b",
  storageBucket: "winzco-7b87b.appspot.com",
  messagingSenderId: "709184532920",
  appId: "1:709184532920:web:c8088b61e2dc3a17629f4d",
  measurementId: "G-FHHM3ECF3S",
};
export default config;
