import React, { useState } from "react";
import { useUser } from "../context/UserContext";
import { loginAdmin } from "../services/index";
import { message } from "antd";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../registerFirebase";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import logo from "../assets/logo.png";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { addDataToUser } = useUser();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const loginUser = React.useCallback(
    (event) => {
      event.preventDefault();
      setLoading(true);
      if (!email || !password) {
        console.log("Email,,", email);
        console.log("Password,,", password);
        messageApi.open({
          type: "error",
          content: "Plese enter email and password!",
        });
        setLoading(false);
      } else {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            addDataToUser(user);
            console.log(user);
            localStorage.setItem("token", user?.accessToken);
            loginAdmin(user.uid, user?.accessToken)
              .then((res) => {
                setLoading(false);

                console.log("Response...", res);
              })
              .catch((e) => {
                setLoading(false);
                messageApi.open({
                  type: "error",
                  content: "Sorry! You are not admin",
                });
              });
          })
          .catch((error) => {
            setLoading(false);
            messageApi.open({
              type: "error",
              content: "Sorry! You are not admin",
            });
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
          });
      }
    },
    [email, password]
  );
  return (
    <>
      <div>
        {/* Hello world */}
        {contextHolder}

        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Winzco</title>
        <meta content name="description" />
        <meta content name="keywords" />
        {/* Favicons */}
        <link href="assets/img/favicon.png" rel="icon" />
        <link href="assets/img/apple-touch-icon.png" rel="apple-touch-icon" />
        {/* Google Fonts */}
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Nunito:300,300i,400,400i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
          rel="stylesheet"
        />
        {/* Vendor CSS Files */}
        <link
          href="assets/vendor/bootstrap/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/bootstrap-icons/bootstrap-icons.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/boxicons/css/boxicons.min.css"
          rel="stylesheet"
        />
        <link href="assets/vendor/quill/quill.snow.css" rel="stylesheet" />
        <link href="assets/vendor/quill/quill.bubble.css" rel="stylesheet" />
        <link href="assets/vendor/remixicon/remixicon.css" rel="stylesheet" />
        <link
          href="assets/vendor/simple-datatables/style.css"
          rel="stylesheet"
        />
        {/* Template Main CSS File */}
        <link href="assets/css/style.css" rel="stylesheet" />
        {/* =======================================================
  * Template Name: NiceAdmin - v2.4.1
  * Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== */}
        <main>
          <div className="container">
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-4 d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex justify-content-center py-4">
                    
                        <img src={logo} alt="Winzco" height="100" width="200" />
                        {/* <span className="d-none d-lg-block">Winzco</span> */}
              
                    </div>
                    {/* End Logo */}
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pt-4 pb-2">
                          <h5 className="card-title text-center pb-0 fs-4">
                            Login to Your Account
                          </h5>
                          <p className="text-center small">
                            Enter your username &amp; password to login
                          </p>
                        </div>
                        <form className="row g-3 needs-validation" noValidate>
                          <div className="col-12">
                            <label
                              htmlFor="yourUsername"
                              className="form-label"
                            >
                              Email
                            </label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                @
                              </span>
                              <input
                                onChange={(e) => setEmail(e.target.value)}
                                type="text"
                                name="username"
                                className="form-control"
                                id="yourUsername"
                                required
                              />
                              <div className="invalid-feedback">
                                Please enter your username.
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <label
                              htmlFor="yourPassword"
                              className="form-label"
                            >
                              Password
                            </label>
                            <input
                              onChange={(e) => setPassword(e.target.value)}
                              type="password"
                              name="password"
                              className="form-control"
                              id="yourPassword"
                              required
                            />
                            <div className="invalid-feedback">
                              Please enter your password!
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="remember"
                                defaultValue="true"
                                id="rememberMe"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rememberMe"
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                          <div className="col-12">
                            {loading ? (
                              <LoadingOutlined
                                className="w-100"
                                style={{ fontSize: 24, alignSelf: "center" }}
                                spin
                              />
                            ) : (
                              <button
                                onClick={(e) => loginUser(e)}
                                className="btn btn-primary w-100"
                                type="submit"
                              >
                                Login
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        {/* End #main */}
        <a
          href="#"
          className="back-to-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short" />
        </a>
        {/* Vendor JS Files */}
        {/* Template Main JS File */}
      </div>
    </>
  );
};

export default Login;
