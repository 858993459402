import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  Popconfirm,
  Table,
  Typography,
  Button,
} from "antd";
import FormModal from "../components/FormModalCampaigns";
import moment from "moment";
import {
  getCampaigns,
  deleteCampaigns,
  updateCampaigns,
  getCampaignDetails,
} from "../../src/services";
import { useNavigate } from "react-router-dom";
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    title === "Status" ? (
      <Select>
        <Select.Option value="ACTIVE">ACTIVE</Select.Option>
        <Select.Option value="INACTIVE">INACTIVE</Select.Option>
      </Select>
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Campaigns = () => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [token, setToken] = useState(localStorage.getItem('token'))
  const navigate = useNavigate();

  const campaignDetails = (id) => {
    getCampaignDetails(id, token).then((res) => {
      navigate("/campaignsDetails", {
        state: {
          data: res,
        },
      });
    });
  };

  React.useEffect(() => {
    getCampaigns(token).then((res, index) => {
      setDataSource(
        res.map((row, index) => ({
          key: row.id,
          campaignCategoryId: row.campaignCategoryId,
          productId: row.productId,
          prizeId: row.prizeId,
          name: row.name,
          prize: row?.prize?.name,
          product: row?.product?.name,
          drawdate: row?.draws.map((res) => <p>{!res?.deletedAt &&res?.drawDateTime}</p>),
          drawDateId: row?.draws[0]?.id,
          totalcoupons: row?.noOfCoupons,
          status: row?.status,
          details: (
            <div>
              {" "}
              <a className="nav-link " onClick={() => campaignDetails(row.id)}>
                <p
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                >
                  Details
                </p>
              </a>
            </div>
          ),
        }))
      );
    });
  }, []);
  const [count, setCount] = useState(2);
  const handleDelete = async (key) => {
    await deleteCampaigns(key, token).then((res) => {
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);
    });
  };
  const defaultColumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "20%",
      editable: false,
    },
    {
      title: "Prize",
      dataIndex: "prize",
      editable: false,
    },
    {
      title: "Product",
      dataIndex: "product",
      editable: false,
    },
    {
      title: "Draw Date",
      dataIndex: "drawdate",
      editable: false,
    },
    {
      title: "Total no of Coupons",
      dataIndex: "totalcoupons",
      editable: false,
    },
    {
      title: "Status",
      dataIndex: "status",
      editable: true,
    },
    {
      title: "Details",
      dataIndex: "details",
      editable: false,
    },
    {
      title: "Actions",
      width: "20%",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            {dataSource.length >= 1 ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.key)}
              >
                <a style={{ marginLeft: 10, color: "red" }}>Delete</a>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];
  const handleAdd = (
    name,
    prize,
    product,
    formattedDate,
    status,
    noOfCoupons
  ) => {
    const newData = {
      key: count,
      name,
      prize,
      product,
      drawdate: formattedDate,
      status,
      totalcoupons: noOfCoupons,
      details: (
        <div>
          {" "}
          <a className="nav-link " href="campaignsDetails">
            <p
              style={{
                color: "blue",
                fontWeight: "bold",
                textDecoration: "underline",
                marginTop: "20px",
                cursor: "pointer",
              }}
            >
              Details
            </p>
          </a>
        </div>
      ),
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const updateModal = React.useCallback(
    (param) => {
      setModalVisible(param);
    },
    [modalVisible]
  );

  React.useEffect(() => {}, [modalVisible]);

  const [form] = Form.useForm();
  const [data, setData] = useState(dataSource);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        console.log("newData[index].drawdate", newData[index]);

        const payload = newData[index]?.drawDateId
          ? {
              id: newData[index].key,
              campaignCategoryId: newData[index].campaignCategoryId,
              productId: newData[index].productId,
              prizeId: newData[index].prizeId,
              name: newData[index].name,
              status: newData[index].status,
              draws: [
                {
                  id: newData[index]?.drawDateId,
                  drawDateTime: moment(
                    newData[index]?.drawdate[0]?.props.children
                  ).format(),
                },
              ],
            }
          : {
              id: newData[index].key,
              campaignCategoryId: newData[index].campaignCategoryId,
              productId: newData[index].productId,
              prizeId: newData[index].prizeId,
              name: newData[index].name,
              status: newData[index].status,
              noOfCoupons: newData[index]?.totalcoupons,
            };

        updateCampaigns(payload, token).then((res) => {
          setData(newData);
          setDataSource(newData);
          setEditingKey("");
        });
      } else {
        newData.push(row);
        setData(newData);
        setDataSource(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      {modalVisible && (
        <FormModal
          modalVisible={modalVisible}
          updateModal={updateModal}
          handleAdd={handleAdd}
        />
      )}
      <main id="main" className="main">
        <section className="section dashboard">
          <div>
            <Button
              onClick={() => {
                // handleAdd()
                setModalVisible(true);
              }}
              type="primary"
              style={{
                marginBottom: 16,
              }}
            >
              Add new campaign
            </Button>

            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={dataSource}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }}
              />
            </Form>
          </div>
        </section>
      </main>
    </div>
  );
};
export default Campaigns;
