import React from "react";
import "../App.css";

import { redirect } from "react-router-dom";

const SideBar = () => {
  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <a className="nav-link " href="/">
            <i className="bi bi-grid" />
            <span>Dashboard</span>
          </a>
        </li>

        <li className="nav-item">
          <a className="nav-link collapsed" href="users">
            <i className="bi bi-card-list" />
            <span>Users</span>
          </a>
        </li>
        {/* End Profile Page Nav */}
        <li className="nav-item">
          <a className="nav-link collapsed" href="campaigns">
            <i className="bi bi-card-list" />
            <span>Campaigns</span>
          </a>
        </li>
        {/* End Profile Page Nav */}
        <li className="nav-item">
          <a className="nav-link collapsed" href="campaignCategories">
            <i className="bi bi-card-list" />
            <span>Campaign categories</span>
          </a>
        </li>
        {/* End Contact Page Nav */}
        <li className="nav-item">
          <a className="nav-link collapsed" href="products">
            <i className="bi bi-card-list" />
            <span>Products</span>
          </a>
        </li>
        {/* End Register Page Nav */}
        <li className="nav-item">
          <a className="nav-link collapsed" href="prize">
            <i className="bi bi-card-list" />
            <span>Prize</span>
          </a>
        </li>
        {/* End Register Page Nav */}
        <li className="nav-item">
          <a className="nav-link collapsed" href="draws">
            <i className="bi bi-card-list" />
            <span>Draws</span>
          </a>
        </li>

        {/* End Register Page Nav */}
        <li className="nav-item">
          <a className="nav-link collapsed" href="winners">
            <i className="bi bi-card-list" />
            <span>Winners</span>
          </a>
        </li>
        {/* End Register Page Nav */}
        <li className="nav-item">
          <a className="nav-link collapsed" href="coupons">
            <i className="bi bi-card-list" />
            <span>Coupons</span>
          </a>
        </li>
        {/* End Register Page Nav */}
        <li className="nav-item">
          <a className="nav-link collapsed" href="notifications">
            <i className="bi bi-card-list" />
            <span>Push Notifications</span>
          </a>
        </li>
        {/* End Register Page Nav */}
        <li className="nav-item">
          <a className="nav-link collapsed" href="currency">
            <i className="bi bi-card-list" />
            <span>MultiCurrency</span>
          </a>
        </li>
        {/* End Register Page Nav */}
        <li className="nav-item">
          <a className="nav-link collapsed" href="shipping">
            <i className="bi bi-card-list" />
            <span>Shipping Charges</span>
          </a>
        </li>
        {/* End Register Page Nav */}
        <li className="nav-item">
          <a className="nav-link collapsed" href="orders">
            <i className="bi bi-card-list" />
            <span>Orders</span>
          </a>
        </li>
        {/* End Register Page Nav */}
        <li className="nav-item">
          <a className="nav-link collapsed" href="sliders">
            <i className="bi bi-card-list" />
            <span>Sliders</span>
          </a>
        </li>
        {/* End Register Page Nav */}
        <li
          className="nav-item"
          onClick={() => {
            localStorage.removeItem("userDataLocal");
            localStorage.removeItem("token");
            window.location.reload();
          }}
        >
          <a className="nav-link collapsed" href="">
            <i className="bi bi-box-arrow-in-right" />
            <span>Logout</span>
          </a>
        </li>
        {/* End Login Page Nav */}
      </ul>
    </aside>
  );
};
export default SideBar;
