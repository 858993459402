import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../registerFirebase";
import { useState } from "react";

export default function useFileUpload() {
  const [percent, setPercent] = useState(0);
  const [fileUrl, setFileUrl] = useState(null);

  async function handleUpload(file) {
    if (!file) {
      alert("Please upload an image first!");
      return;
    }

    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    const uploadPromise = new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
         
          setPercent(percent);
        },
        (err) => reject(err),
        () => resolve()
      );
    });

    const urlPromise = getDownloadURL(uploadTask.snapshot.ref);

    await Promise.all([uploadPromise, urlPromise]);

    const url = await urlPromise;
    setFileUrl(url);

    return { percent, fileUrl: fileUrl || url };
  }

 

  return { handleUpload, percent, fileUrl };
}
