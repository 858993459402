import React,{ useState } from 'react'
import { Modal, Button, Form, Input,Select, DatePicker,TimePicker, Radio, message,InputNumber } from 'antd'
import {addCampaigns, getPrizes,getProducts,getCampaignCategories} from '../services'
import dayjs from 'dayjs';
import moment from 'moment'
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);


export default function FormModal({modalVisible, updateModal, handleAdd}) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(modalVisible)
    const [prizes, setPrizes] = useState([])
    const [products, setProducts] = useState([])
    const [type, setType] = useState(1)
    const [date, setData] = useState('')
    const [time, setTime] = useState('')
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [categories, setCategories] = useState([])
    const value=[]
    const onChange = (time, timeString) => {
        setTime(timeString)
    };
    React.useEffect(()=>{
        getPrizes(token).then((res, index)=>{
            setPrizes(res)
        })
      },[])

      React.useEffect(()=>{
        getCampaignCategories(token).then((res, index)=>{
          
            setCategories(res)
         
        })
      },[])

      React.useEffect(()=>{
        getProducts(token).then((res, index)=>{
            setProducts(res)
         
        })
      },[])

    const addCampignsCategoriesData = () =>{
        form.submit()
        const {name, noOfCoupons, prizeId,productId,status,Select,campaignCategoryId} =form.getFieldsValue()
      
  
        if(name  && prizeId && productId && status&&campaignCategoryId){

            const updatedDate = moment(date.$d).format().split("T")[0]
         
            const data = date? 
            {
                
                name,
               status,
               noOfCoupons,
               prizeId,
               productId,
               campaignCategoryId,
               "draws":[
                  { drawDateTime:updatedDate+'T'+time}

               ]
            
                
            }:{
                name,
                status,
                noOfCoupons,
                prizeId,
                productId,
                campaignCategoryId,
            }
        
       
        addCampaigns(data, token).then(res=>{
      
            if(res.code === "400"){
                console.log('Inside if')
                messageApi.open({
                    'type':'error',
                    content:`Campaign already exist!`
                  })
               
            }
            else{
                updateModal(false)
                setIsModalVisible(false)

                
                const prize = prizes.filter(res=>res.id === prizeId)
                const product = products.filter(res=>res.id === productId)
                handleAdd(name, prize[0].name, product[0].name, date && updatedDate+'T'+time,status,noOfCoupons)
            }
            
        }).catch((err)=>{
            console.log('err', err)
        })
    }
      
       
    }

    const handleCancel = () => {
        updateModal(false)
        setIsModalVisible(false)
        form.resetFields()
    }

  

    // const onFinish = (data) => {
    //     if(error){
    //         messageApi.open({
    //             'type':'error',
    //             content:`Status should be "ACTIVE" OR "INACTIVE"`
    //           })
    //     }else{
    //     updateModal(false)
    //     setIsModalVisible(false)
    //     }
    // }

    const onChangeType = (res) =>{
        setType(res.target.value)

    }

    const updateDate = (val) => {
        setData(val)
    }


    return (
        <>
{contextHolder}
            <Modal
                title="Add Campaigns"
                visible={isModalVisible}
                
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={addCampignsCategoriesData}>
                        Submit
                    </Button>,
                ]}
            >
               
                <Form labelCol={{ xs: { span: 6 } }} wrapperCol={{ xs: { span: 12 } }} form={form} scrollToFirstError>
                    <Form.Item name="prizeId" label="Prize" rules={[{ required: true, message: "This field is required." }]}>
                    <Select name="selectprize" placeholder="Please select a prize">
                    {prizes.map(res=>{
                        return(
                        <Select.Option value={res.id}>{res.name}</Select.Option>
                        )
                    })}
             
             
   
            </Select>
                    </Form.Item>

                    <Form.Item name="productId" label="Product" rules={[{ required: true, message: "This field is required." }]}>
                    <Select name="selectproduct" placeholder="Please select a product">
                    {products.map(res=>{
                        return(
                        <Select.Option value={res.id}>{res.name}</Select.Option>
                        )
                    })}
   
            </Select>
                    </Form.Item>


                    <Form.Item name="campaignCategoryId" label="Category" rules={[{ required: true, message: "This field is required." }]}>
                    <Select name="selectproduct" placeholder="Please select a Campaign Category">
                    {categories.map(res=>{
                        return(
                        <Select.Option value={res.id}>{res.name}</Select.Option>
                        )
                    })}
   
            </Select>
                    </Form.Item>

                    

                   
                    <Form.Item name="status" label="Status" rules={[{ required: true, message: "This field is required." }]}>
                    <Select>
                    <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                    <Select.Option value="INACTIVE">INACTIVE</Select.Option>
                    </Select>
                    </Form.Item>

                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "This field is required." }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="Select" label="Select draw type:">
                
                <Radio.Group defaultValue={type}  style= {{marginLeft:10}}onChange={onChangeType} value={type}>
  <Radio value={1}>Draw Date</Radio>
  <Radio value={2}>Coupon</Radio>

</Radio.Group>
                </Form.Item>


                    {type === 1 && <Form.Item name="date" label="Draw Date" rules={[{ required: true, message: "This field is required." }]}>
                   
                    <DatePicker onChange={updateDate} />
                    </Form.Item>}

                    {type ===1 &&<Form.Item name="time" label="Draw Time" rules={[{ required: true, message: "This field is required." }]}>
                    <TimePicker onChange={onChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />
                    </Form.Item>}

                    {type ===2 && <Form.Item name="noOfCoupons" label="No of Coupons" rules={[{ required: true, message: "This field is required." }]}>
                    <InputNumber />
                    </Form.Item>}
                   
                  
                </Form>
            </Modal>
        </>
    )
}